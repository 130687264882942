import React, { useContext } from "react";
import Souscripteur from "./Souscripteur.js";
import Defunt from "./Defunt.js";
import Declarant from "./Declarant.js";
import Beneficiaires from "./Beneficiaires.js";
import Confirm from "./Confirm.js";
import Stepper from "./Stepper.js";
import { AppContext } from "../../../context/ContextApi.js";
import {FaUserTie} from "react-icons/fa";
import SouscripteurInfo from "../recherche/form/SouscripteurInfo";

const SignupMulti = () => {
    const { step, steps } = useContext(AppContext);
    const renderCurrentSelection = () => {
        switch (step) {
            case 1:
                return <Souscripteur />;
            case 2:
                return <Defunt />;
            case 3:
                return <Declarant />;
            case 4:
                return <Beneficiaires />;
            case 5:
                return <Confirm />;
            default:
                return null;
        }
    };

    return (
        <>
            <div className="card-title py-3 text-3xl m-0 text-center">DECLARATION DE SINISTRE</div>
            <div className="card-content lightest-main p-4">
                <div className="container horizontal stepper-container-horizontal"><Stepper/></div>
                <div className="py-3 login-container-mobile">{renderCurrentSelection()}</div>
            </div>
        </>
    );
};

export default SignupMulti;
