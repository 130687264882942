import React, {useCallback, useEffect, useState} from "react";
import "./header.css";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { TfiWrite } from "react-icons/tfi";
import { MdFollowTheSigns } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import "./assistance/assistanceContainer.css";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaUserLock } from "react-icons/fa";
import { Button, Modal } from 'react-bootstrap';

const Header = () => {
    const [navButton, setNavButton] = useState("");
    const [showModal, setShowModal] = useState(false);
    let location = useLocation();
    const navigate = useNavigate();
    let locationPath = location.pathname;
    const declarerPath = ["/declare", "/search", "/signup", "/success"];
    const suivrePath = [
        "/login",
        "/readcode",
        "/newcode",
        "/suivrehome",
        "/piece",
        "/courierdecla",
        "/couriersini",
        "/allmailContainer",
        "/history",
    ];
    const assisterPath = [
        "/assistance",
        "/faq",
        "/rdv",
        "/rdvsuccess",
        "/guide",
        "/tutorial",
    ];
    let data = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        if (declarerPath.includes(locationPath)) setNavButton("declaration");
        else if (suivrePath.includes(locationPath)) setNavButton("suivi");
        else if (assisterPath.includes(locationPath)) setNavButton("assistance");
    }, [locationPath, navButton]);

    // Fonction de déconnexion
    const logout = () => {
        localStorage.clear();
        setShowModal(false);
        navigate("/");
    };

    // Ouvrir le modal
    const openModal = () => setShowModal(true);

    // Fermer le modal
    const closeModal = () => setShowModal(false);

    return (
        <>
            <header id="header" className="fixed-top">
                <div className="container d-flex align-items-center justify-content-between">
                    <div>
                        <Link style={{ textDecoration: "none" }} to="/">
                          <img src="./logo_sinistre_online.png" className="img-design" alt="sinistre online"/>
                        </Link>
                    </div>

                    {locationPath === "/" ? (
                        <div className="get-started-btn"></div>
                    ) : (
                        <div className="get-started-btn">
                            <Link to="/search" type="button" className={`btn bts-sm ${navButton === "declaration" ? "btn-warning" : "btn-primary"} px-2 ms-1`}>
                                <span className="flex justify-center items-center">
                                    <TfiWrite />
                                    <label className="d-none d-md-inline ps-1">Déclaration</label>
                                </span>
                            </Link>
                            <Link to={(data !== null) ? 'suivrehome' : 'login'} type="button" className={`btn bts-sm ${navButton === "suivi" ? "btn-warning" : "btn-primary"} px-2 ms-1`}>
                                <span className="flex justify-center items-center">
                                    <MdFollowTheSigns />
                                    <label className="d-none d-md-inline ps-1">Suivi</label>
                                </span>
                            </Link>
                            <Link to="/assistance" type="button" className={`btn bts-sm ${navButton === "assistance" ? "btn-warning" : "btn-primary"} px-2 ms-1`}>
                                <span className="flex justify-center items-center">
                                    <BiSupport />
                                    <label className="d-none d-md-inline ps-1">Assistance</label>
                                </span>
                            </Link>
                        </div>
                    )}
                    {data !== null ? (
                        <div className="get-started-btn">
                            <Link to="/allmailContainer">
                                <button type="button" className={(data.data.courriers.nouveaux > 0) ? 'btn bts-sm btn-primary px-2 ms-1 relative inline-flex -right-4' : 'btn bts-sm btn-primary mx-1'}>
                                    <BsFillEnvelopeFill size={23} className="d-none d-md-block"/>
                                    <BsFillEnvelopeFill className="d-block d-md-none"/>
                                </button>
                                {(data.data.courriers.nouveaux > 0) ? (
                                    <label className="no-underline relative inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-5 dark:border-gray-900">
                                        {data.data.courriers.nouveaux}
                                    </label>
                                ) : ("")}
                            </Link>
                            <button type="button" className="btn bts-sm btn-primary px-2" title="Déconnexion" onClick={openModal}>
                                <FaUserLock size={23} className="d-none d-md-block"/>
                                <FaUserLock className="d-block d-md-none"/>
                            </button>

                            <Modal show={showModal} onHide={closeModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Confirmation de déconnexion</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    Êtes-vous sûr de vouloir vous déconnecter ?
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={closeModal}>
                                        Annuler
                                    </Button>
                                    <Button variant="primary" onClick={logout}>
                                        Déconnexion
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    ) : null}
                </div>
            </header>
        </>
    );
};

export default Header;
