import React from 'react'
import './assistanceContainer.css'
import { Link } from "react-router-dom";
import { RiFileSearchFill } from "react-icons/ri";
import { BsFillCalendar2CheckFill } from "react-icons/bs";
import { SiFiles } from "react-icons/si";
import { FaFileAudio } from "react-icons/fa";

const AssistanceContainer = () =>
{
    return (
        <section id='hero' className="d-flex align-items-center justify-content-center" style={{backgroundColor: '#dfe2ea', top: "center", width: '100%'}}>
            <div className="container" data-aos="fade-up">
                <div className="justify-content-center align-items-center font-primary p-4 rounded-4" data-aos="zoom-in" data-aos-delay="150">
                    <div className="assistanceContainer" data-aos="fade-up">
                        <h2 className='bvn-phrase mb-3'>Bienvenue dans l'assistance</h2>
                        <h4 className="plate-form px-md-5">Cliquez sur l'onglet de votre choix pour être accompagné(e) dans votre déclaration de sinistre et le suivi de votre dossier.</h4>
                        <div className="row mt-4 flex justify-center" style={{color: '#ffffff'}}>
                            <div className="col-4 col-md-3">
                                <Link to="/faq" type="button" className="btn bts-sm btn-light line-info m-1 w-100">
                                    <span style={{display: 'flex', justifyContent: 'center', alignItems: "center"}}>
                                        <RiFileSearchFill size={35}/>
                                    </span>
                                    <span className="d-block fw-bold d-md-none">FAQ</span>
                                    <span className="d-none fw-bold d-md-block">Foire aux questions</span>
                                </Link>
                            </div>
                            <div className="col-4 col-md-3">
                                <Link to="/rdv" type="button" className="col-md-3 btn bts-sm btn-light line-info m-1 w-100">
                                    <span style={{display: 'flex', justifyContent: 'center', alignItems: "center"}}>
                                        <BsFillCalendar2CheckFill size={35} />
                                    </span>
                                    <span className="d-block fw-bold d-md-none">RDV</span>
                                    <span className="d-none fw-bold d-md-block">Prise de rendez-vous</span>
                                </Link>
                            </div>
                            {/*<div className="col-4 col-md-3">*/}
                            {/*    <Link to="/guide" type="button" className="col-md-3 btn bts-sm btn-light line-info m-1 w-100">*/}
                            {/*        <span style={{display: 'flex', justifyContent: 'center', alignItems: "center"}}>*/}
                            {/*            <SiFiles size={35} />*/}
                            {/*        </span>*/}
                            {/*        <span className="d-block fw-bold d-md-none">Guide</span>*/}
                            {/*        <span className="d-none fw-bold d-md-block">Guide des pièces</span>*/}
                            {/*    </Link>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AssistanceContainer
