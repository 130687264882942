import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../../context/ContextApi.js";
import SouscripteurInfo from '../form/SouscripteurInfo.js'
import { FaUserTie } from 'react-icons/fa';

export default function Type()
{
    const { selectedOption, setSelectedOption, selectedItem, setSelectedItem, successful, setSuccessful, message, setMessage, showAlert, alert } = useContext(AppContext)
    const handleCheckbox = (e) =>
    {
        setSelectedOption(e.target.value)
    }

    return (
        <>
            <div className="card-title flex justify-center items-center py-3 text-2xl text-center m-0">
                <FaUserTie size={25} className="me-2"/> INFOS CLIENT
            </div>
            <div className="card-content p-4">
                {selectedOption === "1" && <SouscripteurInfo/>}
            </div>
        </>
    );
}
