import React, { useContext, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './individuel.css'
import { AppContext } from '../../../context/ContextApi';
import { FaThumbsUp } from 'react-icons/fa';
import { TfiWrite } from 'react-icons/tfi';
import { MdFollowTheSigns } from 'react-icons/md';
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";

const Success = () =>
{
    const { selectedOption, setSelectedOption, selectedItem, setSelectedItem, declareAcceuil, setDeclareAcceuil, handleEmpty } = useContext(AppContext)
    let navigate = useNavigate()
    let data = JSON.parse(localStorage.getItem('numero'));
    let startingData = {
        numero_declaration: '054/2023',
    }
    let response = data?.data || startingData;
    useEffect(() =>
    {

    }, [selectedOption, selectedItem])
    return (
        <div className="justify-content-center align-items-center bvn bg-light p-4 rounded-4" data-aos="fade-up" data-aos-delay="150">
            <h2 className="flex my-3 text-3xl justify-center items-center custom-with-label" style={{color: '#0187be'}}>
                <FaThumbsUp size={70}/></h2>
            <h4 className="my-3 text-secondary custom-with-label">
                Votre déclaration de sinistre a bien été enregistrée. <br/>
                Nos équipes traiteront votre requête avec soin et un retour vous sera fait dans un délai de deux (2) jours ouvrés.
            </h4>
            <div className="flex flex-col justify-conter align-center w-100">
                <p className="text-2xl font-normal custom-with-label" style={{color: '#001e60'}}>
                    Suivez votre déclaration en cliquant ici et en renseignant le numéro suivant :
                    <label className="font-bold text-3xl ms-2" style={{color: "#cc992d"}}>{response?.numero_declaration}</label>
                </p>
                <div className="flex justify-center align-center text-center py-3">
                    <button onClick={() => handleEmpty(navigate, 'login')} type="button"
                            className="btn btn-sm btn-primary d-flex justify-content-center align-items-center rounded-pill px-4">
                        <MdFollowTheSigns size={30} className='mr-2'/> SUIVRE MON DOSSIER
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Success
