import axios from "axios";
import React, { useContext, useState, useCallback, useMemo } from "react";
import { AppContext } from "../../../../context/ContextApi";
import { toast } from "react-toastify";
import global_variables from "../../../controllers/globalVar.js";
import Loader from "../../../loading/Loading.js";
import { BiSave } from "react-icons/bi";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { CgEye } from "react-icons/cg";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Button2 from "react-bootstrap/Button";
import Modal2 from "react-bootstrap/Modal";

const style = {
  margin: 12,
  height: 700,
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function FilePreview({ id, type }) {
  const newplugin = defaultLayoutPlugin();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  // const {
  //   individuel,
  //   individuelNextStep,
  //   individuelPreviousStep,
  //   setFileName,
  //   setFileName2,
  //   setFileName3,
  //   staticFields,
  //   setStaticFields,
  //   staticSelectedCheckboxes,
  //   setStaticSelectedCheckboxes,
  //   dynamicSelectedCheckboxes,
  //   setDynamicSelectedCheckboxes,
  //   newPieceDynamicBox,
  //   setNewPieceDynamicBox,
  //   newPieceDynamicFields,
  //   setNewPieceDynamicFields,
  // } = useContext(AppContext);
  const {
    unicFormStaticBox,
    setUnicFormStaticBox,
    unicFormStaticFields,
    setUnicFormStaticFields,
  } = useContext(AppContext);
  const [imageFields, setImageFields] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [done, setDone] = useState(false);
  const [pdfFile, setPDFfile] = useState(null);
  const [viewPdf, setViewPdf] = useState("");
  const [open, setOpen] = React.useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showModal, setShowModal] = useState(false);

  let data = JSON.parse(localStorage.getItem("user"));
  let numDeclaration = useMemo(() => [data.data.numero_declaration], [
    data.data.numero_declaration,
  ]);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const fileType = ["application/pdf"];
  const PreviewComponent = ({ selectedFile }) => {
    console.log("id", selectedFile);
    if (selectedFile) {
      if (
        selectedFile.type.startsWith("image/") ||
        selectedFile.type.startsWith("jpg/") ||
        selectedFile.type.startsWith("png/") ||
        selectedFile.type.startsWith("tiff/") ||
        selectedFile.type.startsWith("bmp/")
      ) {
        const imageUrl = URL.createObjectURL(selectedFile);
        setShowModal(true);
        return (
          <Modal2 show={showModal}>
            <Modal2.Body>
              {" "}
              <img
                src={imageUrl}
                style={{ width: "900px", height: "400px" }}
                alt={`Preview`}
              />
            </Modal2.Body>
            <Modal2.Footer>
              <Button2 variant="secondary" onClick={handleModalClose}>
                Fermer
              </Button2>
            </Modal2.Footer>
          </Modal2>
        );
      } else if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = () => {
          setPDFfile(reader.result);
        };
        setOpen(true);

        return (
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Visualisation
                  </Typography>
                  <CloseIcon
                    onClick={handleClose}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                  {pdfFile && (
                    <>
                      <Viewer fileUrl={pdfFile} pugins={[newplugin]} />
                    </>
                  )}
                  {!pdfFile && <>No PDF</>}
                </Worker>
              </Box>
            </Modal>
          </div>
        );
      } else {
        return <p>Preview not available for this file type.</p>;
      }
    } else {
      return null;
    }
  };

  const handleFileSelect = async (event) => {
    let eventFile = event.target.files[0];
    // const newSelectedImages = [...selectedImages];
    // newSelectedImages[index] = eventFile;

    setSelectedFile(eventFile);
    let convertFile = await convertBase64(eventFile);
    setUnicFormStaticFields({ ...unicFormStaticFields, file: convertFile });
    setSelectedFile(event.target.files[0]);
  };
  const handleTogglePreview = () => {
    setPreviewVisible(!previewVisible);
  };

  const handleClose = () => {
    setOpen(false);
    setPDFfile(null);
    setPreviewVisible(!previewVisible);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setPreviewVisible(!previewVisible);
  };

  let test = [];
  let pieces_beneficiaire = [];

  if (type === "assure") {
    test.push([`${id}`, "scanne", unicFormStaticFields.file]);
    // test.push(id);
    // let nature = "scanne";
    // test.push(`${nature}`);
    // let img = unicFormStaticFields.file;
    // test.push(`${img}`);
  } else if (type === "beneficiaire") {
    pieces_beneficiaire.push([
      `${id}`,
      null,
      "beneficiaire - unic",
      "scanne",
      `${unicFormStaticFields.file}`,
    ]);
  } else {
    return null;
  }

  // console.log("unicForm id and type", id, type);
  // console.log(
  //   "pieces_assure",
  //   test,
  //   "pieces_beneficiaire",
  //   pieces_beneficiaire
  // );

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("unicForm id and type", id, type);
    var data = JSON.stringify({
      numero_declaration: `${numDeclaration}`,
      pieces_assure: test,
      pieces_beneficiaire: pieces_beneficiaire,
    });

    setLoading(true);
    // console.log("data", data);
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${global_variables().SINISTRE_CARE_WEBSERVICE}/add-documents`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setLoading(false);
        toast.success("Votre pièce a bien été enregistrée.", {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        console.log("response of unic", response);
        let child = document.querySelector(`.target-${id}`);
        child.innerHTML = "... En cours";
        setDone(true);
        setShow(false);
        // Refresh the page
        // window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          "Votre pièce n’a pas été enregistrée. Veuillez réessayer svp.",
          {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        console.log(error);
      });
  };
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      ) : done ? (
        <p>Fichier en cours de Traitement </p>
      ) : show ? (
        <form onSubmit={handleSubmit}>
          <div className="d-md-flex justify-between items-center transmettre">
            <div className="flex w-full align-center">
              <input style={{ minWidth: "300px" }}
                className="border-input bg-light p-1 m-2 w-100 rounded outline-none"
                type="file"
                onChange={handleFileSelect}
                required
              />
              {previewVisible && selectedFile && (
                <PreviewComponent selectedFile={selectedFile} />
              )}
            </div>{" "}

            <div className="flex align-items-center">
              <button
                  type="button"
                  onClick={handleTogglePreview}
                  className="btn btn-info"
              >
                <CgEye size={18} />
              </button>
              <button
                  className="text-white relative bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-2 py-1 mx-1 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                  type="submit"
              >
                <span className="flex justify-center items-center">
                  <BiSave size={25} color="#fbbf24" className="mr-1" />{" "}
                  Transmettre
                </span>
              </button>
            </div>
          </div>
        </form>
      ) : null}
    </>
  );
}

export default FilePreview;
